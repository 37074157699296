
body {
  font-family: Calibri;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: normal;
  background-color: #E5EBF0;
  color: #003c71;
}

button {
  font-family: inherit;
}

a {
  color: currentColor;
  text-decoration: none;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}